<!-- 
	This is the dashboard page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

 <template>
	<div class="px-10">

		<div v-if="!loadingData">
			<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" style="padding-top: 50px;">

				<a-col :span="24" :md="16" :lg="16" :xl="16" class="col-form">
					
					<a-card :bordered="false" class="px-5 mx-0 pt-20">
						<a-row :gutter="[24,24]" v-if="currentScreen == 0">
							<a-col :span="24">
								<a-row class="mt-0 pt-2 mb-20">
									<a-col :span="24"  :md="24" :lg="24" :xl="24" class="mb-24 text-center">
										<h4>{{ quiz.title }}</h4>
									</a-col>

									<a-col :span="24"  :md="24" :lg="24" :xl="24" class="mb-15 text-center">
										<label><strong>Quiz Results</strong></label>
									</a-col>

									<!-- <a-col :span="24"  :md="24" :lg="24" :xl="24" class="mb-5 pl-5">
										<h6><strong>{{userDetails.firstName}} {{userDetails.lastName}}</strong></h6>
									</a-col> -->
									<a-col :span="24"  :md="24" :lg="24" :xl="24" class="mt-10 mb-24 text-center">
										<a-progress type="circle" :percent="getScore()" stroke-color="#52C41A" :format="() => '100%'"/>
									</a-col>
								</a-row>


								<a-row class="mt-0 pt-2 mb-20">
									<a-col :span="24"  :md="24" :lg="24" :xl="24" class="mb-24 text-center">
										<a-button :loading="quiz.loading" type="primary" class="" @click="handleStartQuiz"> Review Questions with Answers </a-button>					
									</a-col>
								</a-row>
								
							</a-col>
						</a-row>

						<a-row :gutter="[24,24]" v-if="currentScreen > 0 && questions != null && currentScreen < questions.length + 1">
							<a-col :span="24">

								<a-row class="mt-0 pt-2 mb-20 px-20">

									<a-col :span="24"  :md="24" :lg="24" :xl="24" class="mb-24 px-20 text-center">
										<h6>{{ quiz.title }} Review</h6>
									</a-col>


								</a-row>

								<a-card style="background-color: #F5F5F5; margin: 20px 40px; padding: 30px 30px;">
									<a-row :gutter="[24,24]" v-for="(question, index) in questions" :key="question.uuid">
										<a-col :span="24" v-if="currentScreen == index + 1">
											<a-row class="pt-20 mb-10">
												<a-col :span="24"  :md="24" :lg="24" :xl="24" class="mb-5">
													<label>Question {{ index + 1 }}</label>
												</a-col>

												<a-col :span="24"  :md="24" :lg="24" :xl="24" class="mb-5 pl-5">
													<h6 class="mb-0 pb-0" style="font-weight: bold;">
														<span  id="deltaValue" class="col-oder-2" v-html="quillToHtml(addOps(question.content))"></span>
													</h6>
												</a-col>
											</a-row>

											<a-row :gutter="24">
												<a-col :span="24" :md="24" :lg="24" v-for="(opt, index) in question.choices" :key="index">
													<span style="display: inline !important;"><span style="">{{ getLabel(index) }}.</span> <span  id="deltaValue" class="col-oder-2" v-html="quillToHtml(addOps(opt.content))"></span>
														&nbsp;&nbsp;<a-icon type="check" class="text-success" theme="outlined" v-if="opt.correct" /> 
													</span>
												</a-col>
											</a-row>
											
										</a-col>
									</a-row>
								</a-card>


								<a-row class="mt-20 pt-20 mb-20 px-20">
									<a-col :span="24"  :md="12" :lg="12" :xl="12" class="mb-24 text-center pl-20">
										<a-button id="btnBordered" type="text" class="" @click="previosQuestion" v-if="currentScreen >= 1"> 
											<svg class="text-primary" style="width: 16px;" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
												<path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 12h14M5 12l4-4m-4 4 4 4"/>
											</svg>
											Previous 
										</a-button>
									</a-col>
									<a-col :span="24"  :md="12" :lg="12" :xl="12" class="mb-24 text-center pl-20">
										<a-button id="btnBordered" type="text" class="text-right" @click="nextQuestion"  v-if="currentScreen < questions.length"> 
											Next
											<svg class="text-primary ml-5" style="width: 16px;" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
												<path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 12H5m14 0-4 4m4-4-4-4"/>
											</svg>
										</a-button>					
									</a-col>

								</a-row>

							</a-col>
						</a-row>

					</a-card>
				</a-col>

			</a-row>
		</div>


		<div v-if="loadingData">
			<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="" style="min-height: 60vh">

				<a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">

					<a-spin class="text-primary" size="large"/>

				</a-col>

			</a-row>
		</div>

	</div>
</template>

<script>
	import { DoubleBounce } from 'vue-loading-spinner'
    import { notification } from 'ant-design-vue';
    var QuillDeltaToHtmlConverter = require('quill-delta-to-html').QuillDeltaToHtmlConverter;

	export default ({
		
		components: {
			DoubleBounce,
		},

        props: ['classDetails', 'isCreator'],
		data() {
			return {
				loadingData: false,
                questions: [],
				classUuid: null,
				topicUuid: null,
				quizUuid: null,
				showPublishModal: false,
				message: '',

				currentScreen: 0,

				quiz: {
					title: null,
					instruction: null,
					duration: null,
					questionsCount: null,
					price: null,
					clazz: null,
					topic: null,
					educationSystems: [],
					educationLevels: [],
					educationGrades: [],
					attempts: [],
					subjects: [],
					favorites: [],
					rating: null,
					createdAt: null,
					updatedAt: null,
					uuid: null,
					attemptsCount: 0,
					thumbnail: null,
					mine: false,
					isFree: false,
					favorite: false,
					attempted: false,
					timeout: false,
					score: 0,
					showTimeoutModal: false,
					loading: false,
					showModal: false,
				},

				

				userDetails: {},

				millisecondsInOneMinute: 1000 * 60,

				time: null, //in seconds
        		timer: null,
			}
				
		},
		mounted () {
			let breadcrumbs = [
				{ title: 'Quizzes', url: '/quizzes', isActive: false },
                { title: 'Quiz Results', url: '', isActive: true }
			];
			
			this.$root.$refs.Breadcrumbs.initializeBreadCrumbs(breadcrumbs)
			// localStorage.setItem("breadcrumbs", JSON.stringify(breadcrumbs));
		},
		created() {
			this.quizUuid = this.$route.params.uuid;
			this.getUserDetails();
			this.getQuizDetails();
			this.getQuizQuestions();
			this.getQuizAnswers();
		},
		methods: {


			getScore() {
				let score = (this.quiz.score / this.questions.length) * 100;
				console.log(score.toFixed(1))
				return parseFloat(score.toFixed(1));
			},


			quillToHtml(deltaOps) {

				const jsonDeltaOps = JSON.parse(deltaOps)

				const delta = jsonDeltaOps.ops

				var cfg = {};

				var converter = new QuillDeltaToHtmlConverter(delta, cfg);

				var html = converter.convert();

				return html;
			},


			addOps(value) {
				let formattedValue = ''
				if(value != null && value.includes('{\"ops\":')) {
					formattedValue = value;
				}else {
					let defaultStarter = '{\"ops\":';
					let defaultFinisher = '}';

					formattedValue = `${defaultStarter}${value}${defaultFinisher}`;
				}

				return formattedValue;
			},


			removeOps(value) {

				let trimmedValue = ''

				if(value != null && value.includes('{\"ops\":')) {

					let value2 = value.replace('{\"ops\":', '')

					trimmedValue = value2.substring(0, value2.length - 1);
					
				}else{
					trimmedValue = value;
				}

				return trimmedValue
			},


			getLabel(index) {
                if(index == '0') {
                    return 'a';
                }else if(index == '1') {
                    return 'b';
                }else if(index == '2') {
                    return 'c';
                }else if(index == '3') {
                    return 'd';
                }else if(index == '4') {
                    return 'e';
                }else if(index == '5') {
                    return 'f';
                }else if(index == '6') {
                    return 'g';
                }else if(index == '7') {
                    return 'h';
                }else {
                    return 'a';
                }
            },
			


			async getUserDetails() {

				const userInfo = await localStorage.getItem('user_details')

				if(userInfo != null) {
					let userDetails = JSON.parse(userInfo);

					this.userDetails = userDetails;
					
				}
			},

			async getQuizQuestions() {

				this.loadingData = true;

				let token = await localStorage.getItem("user_token")

                let url = `${this.$BACKEND_URL}/quizzes/questions?quiz=${this.$route.params.uuid}&orderBy=position&order=ASC`;

                this.$AXIOS.get(url).then(async(response) => {
                    if (response.status == 200)  {

						if(response.data.data.length > 0) {
							this.questions = response.data.data;

							// await localStorage.setItem('questionPosition', response.data.length + 1)

						}else{
							this.message = 'No questions available'
						}
                    }
					
					this.loadingData = false;
                }).catch(async(error) => {
					
					this.loadingData = false;

                    if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)

                        this.$router.push(`/sign-in`);
                    }
                });
            },


			async getQuizAnswers() {

				this.loadingData = true;

				let token = await localStorage.getItem("user_token")

                let url = `${this.$BACKEND_URL}/quizzes/attempts/?quiz=${this.$route.params.uuid}&user=${this.userDetails.uuid}&populate=true`;

                this.$AXIOS.get(url).then(async(response) => {
                    if (response.status == 200)  {

						if(response.data.data.length > 0) {
							
							response.data.data.forEach(async res => {
								let correctChoice = await res.question.choices.filter(choice => choice.correct);
								
								if(correctChoice.length > 0 && correctChoice[0].content == res.content) {
									this.quiz.score++
								}

							});

							console.log(this.quiz.score)

							// await localStorage.setItem('questionPosition', response.data.length + 1)

						}
                    }
					
					this.loadingData = false;
                }).catch(async(error) => {
					
					this.loadingData = false;

                    if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)

                        this.$router.push(`/sign-in`);
                    }
                });
            },


			async getQuizDetails() {

				this.loadingData = true;

                let url = `${this.$BACKEND_URL}/quizzes?uuid=${this.$route.params.uuid}`;

                this.$AXIOS.get(url).then(async(response) => {
                    if (response.status >= 200 && response.status < 210)  {

						if(response.data.data.title != null) {
							this.quiz.uuid = response.data.data.uuid;
							this.quiz.title = response.data.data.title;
							this.quiz.instruction = response.data.data.instruction;
							this.quiz.duration = response.data.data.duration;
							this.quiz.questionsCount = response.data.data.questionsCount;
							this.quiz.price = response.data.data.price;
							this.quiz.clazz = response.data.data.clazz;
							this.quiz.topic = response.data.data.topic;
							this.quiz.educationSystems = response.data.data.educationSystems;
							this.quiz.educationLevels = response.data.data.educationLevels;
							this.quiz.educationGrades = response.data.data.educationGrades;
							this.quiz.subjects = response.data.data.subjects;
							this.quiz.favorites = response.data.data.favorites;
							this.quiz.rating = response.data.data.rating;
							this.quiz.createdAt = response.data.data.createdAt;
							this.quiz.updatedAt = response.data.data.updatedAt;
							this.quiz.attemptsCount = response.data.data.attemptsCount;
							this.quiz.thumbnail = response.data.data.thumbnail;
							this.quiz.mine = response.data.data.mine;
							this.quiz.isFree = response.data.data.isFree;
							this.quiz.favorite = response.data.data.favorite;
							this.quiz.attempted = response.data.data.attempted;
							this.quiz.attempts = response.data.data.attempted ? response.data.data.attempts.filter(atte => atte.user == this.userDetails.uuid) : [];

							this.time = response.data.data.duration * 60;
						}

                    }
					
					this.loadingData = false;
                }).catch(async(error) => {
					
					this.loadingData = false;

                    if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)

                        this.$router.push(`/sign-in`);
                    }
                });
            },


			handleStartQuiz() {
				this.currentScreen = 1;
			},

			onSelectAnswer(event, index) {
				// this.questions[index].answer = event.target.value
			},

			nextQuestion() {
				if(this.currentScreen <= this.questions.length) {
					this.currentScreen++;
				}
			},


			previosQuestion() {
				if(this.currentScreen >= 1) {
					this.currentScreen--;
				}
			},

			

			notify(message, type) {
                notification[type]({
                    message: message,
                    description: '',
                    placement: 'topRight',
                    duration: 3,
                });
                
            },

		}
	})

</script>

<style scoped>

.double-bounce1[data-v-33aee302], .double-bounce2[data-v-33aee302] {
	background-color: #734EBF !important;
	height: 70px;
	width: 70px;
}

a:hover {
	color: inherit !important;
}

#btn, #btn:hover {
	background-color: #734EBF !important;
	color: white !important;
}

.ant-switch-checked {
    background-color: #734EBF !important;
}

#startingTime {
    padding-bottom: 0%;
	margin-bottom: 0%;
	height: 17px;
}

#deleteBtn, #deleteBtn:hover { 
	background-color: inherit !important;
	border: none;
	color: #F5222D;
}

#publishBtn, #publishBtn:hover, #publishBtn:focus { 
	background-color: inherit !important;
	border-color: #734EBF;
	color: #734EBF;
}

#editBtn, #editBtn:hover { 
	background-color: inherit !important;
	border: none;
	color: #734EBF;
}


p, #deltaValue, #deltaValue > p {
    display: inline-block !important;
}
/* .ant-tabs-nav:hover, .ant-tabs-tab-active {
	color: #734EBF !important;
	border-bottom: 1px solid#734EBF;
} */
</style>